import * as React from "react"
import { Link, graphql } from "gatsby"
import HeroImg from "../images/production-set-1.png"
import User2 from "../images/user-1.jpeg"
import ProfileMarquee from '../components/ProfileMarquee';

import Layout from "../components/layout"
import Seo from "../components/seo"

const BlogIndex = ({ data, location }) => {

  const profileData = data.allMarkdownRemark.nodes;

  return (
    <Layout location={location}>

      <div className="bg-gray-900 text-white">

        <div className="p-5 md:py-10">
          
          <div className="flex flex-col justify-between">

          <div className="container mx-auto">
            <h1 className="text-4xl md:text-8xl leading-9 md:leading-[5rem] mb-3 font-bold uppercase">
            We revolutionize the way production jobs are found and filled
            </h1>
            <h2 className="text-md md:text-2xl font-semibold uppercase mb-4">
            CREWZIP is your gateway to success.
            </h2>
            <div className="md:py-5">
              <div className="md:w-3/4">
              Discover premier production job opportunities with unparalleled speed. Connect effortlessly with talented production staff for your projects. Your search for exceptional production personnel ends here.
              </div>
              <div className="my-5 md:my-0 md:w-7 relative float-right bottom-0 md:bottom-10 right-5">
                <Link to="/profiles"><span className="text-3xl">→</span></Link>
              </div>
            </div>
          </div>

          <div className="relative container mx-auto">
            <Link to="/profiles"><img src={HeroImg} className="w-full" /></Link>
            <div class="h-72 lg:h-96 bg-gradient-to-t from-gray-900  absolute w-full bottom-0 z-20" />
          </div>

        </div>

      </div>

      <div className="py-10">
        <ProfileMarquee profiles={profileData} />
      </div>
  
        <div className="md:py-10">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-0">
            <div className="">
              <Link to="/search"><img src={User2} className="w-full" /></Link>
            </div>
            <div className="flex flex-col justify-between bg-gray-900 text-white p-5 md:py-0 md:px-10  h-full" >
              <h1 className="text-4xl md:text-8xl font-bold leading-10 md:leading-[5rem] mb-5 uppercase md:my-0">Find and Share your crew</h1>
              <p className="mb-5 md:text-lg md:leading-6">CREWZIP is a dynamic production job matching platform. <br /><br /> Connecting seamlessly with top premier talent and production opportunities, ensuring every project reaches its creative potential.</p>
              <div className="my-5 md:my-0"><Link to="/search"><button className=" bg-gray-700 border border-white bg-transparent text-white focus:text-white py-4 w-full px-4 hover:bg-gray-800 text-md">Search Profiles</button></Link></div>
            </div>
          </div>
        </div>
          
        {/* <div className="grid grid-cols-1 md:grid-cols-3 gap-4 p-8 border-t border-gray-600">
          <div className="">
            <h1 className="text-xl font-semibold">Pioneering research on the path to AGI</h1>
            <div>Learn about our research</div>
          </div>
          <div className="">
            <h1 className="text-xl font-semibold">Transforming work and creativity with AI</h1>
            <div>Explore our products</div>
          </div>
          <div className="">
            <h1 className="text-xl font-semibold">Join us in shaping the future of technology</h1>
            <div>View careers</div>
          </div>
        </div> */}

        {/* <div className="border-t border-gray-600">
          <div className="p-8">
            <h2 className="text-xl font-semibold mb-4">Latest updates</h2>
            <div className="flex justify-between items-center">
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                <div className="bg-black border border-white p-4 rounded-lg">
                  <h3 className="leading-5 mb-3">Sam Altman returns as CEO, OpenAI has a new initial board</h3>
                  <p className="text-gray-400 text-sm">Nov 29, 2023</p>
                </div>
                <div className="bg-black border border-white p-4 rounded-lg">
                  <h3 className="leading-5 mb-3">Sam Altman returns as CEO, OpenAI has a new initial board</h3>
                  <p className="text-gray-400 text-sm">Nov 29, 2023</p>
                </div>
                <div className="bg-black border border-white p-4 rounded-lg">
                  <h3 className="leading-5 mb-3">Sam Altman returns as CEO, OpenAI has a new initial board</h3>
                  <p className="text-gray-400 text-sm">Nov 29, 2023</p>
                </div>
                <div className="bg-black border border-white p-4 rounded-lg">
                  <h3 className="leading-5 mb-3">Sam Altman returns as CEO, OpenAI has a new initial board</h3>
                  <p className="text-gray-400 text-sm">Nov 29, 2023</p>
                </div>
              </div>
              <button className="text-gray-400">View all updates</button>
            </div>
          </div>
        </div> */}

      </div>
    </Layout>
  )
}

export default BlogIndex

export const Head = () => <Seo title="Connecting seamlessly with top talent and production opportunities" />

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/content/profile/" } }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      nodes {
        excerpt(pruneLength: 165)
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          name
          description
          profile_img
          tagline
          pronoun
          status
          professional_professions
          professional_projects
          professional_genres
          social_links
        }
      }
    }
  }
`;