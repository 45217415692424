import React from 'react';
import Marquee from 'react-fast-marquee';
import { Link } from 'gatsby';

const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

const getFirstWord = (string) => {
    return string?.split(' ')[0] ?? ''; // Return the first word, or an empty string if undefined
  };

const ProfileMarquee = ({ profiles }) => {
  const shuffledProfiles = shuffleArray([...profiles]).slice(0, 20); // Limit to 20 profiles

  return (
    <Marquee speed={50} gradient={false}>
      {shuffledProfiles.map((profile, index) => (
        <div key={index} className="mx-5 text-center md:w-64 flex gap-8 items-center justify-center">
          <Link to={profile.fields.slug}>
            <img src={profile.frontmatter.profile_img} alt={profile.frontmatter.name} className="mx-auto h-24 w-24 rounded-lg" />
            <h3 className="mt-3 mb-0 text-sm text-white">{profile.frontmatter.name}</h3>
            {/* <p className="text-sm capitalize">{getFirstWord(profile.frontmatter.status)}</p> */}
            <p className="text-xs capitalize text-gray-500  overflow-hidden inline-block h-5">{profile.frontmatter.status}</p>
          </Link>
        </div>
      ))}
    </Marquee>
  );
};

export default ProfileMarquee;
